import type { FunctionComponent } from 'react';
import type { TypeBoxQuantityProps } from './types';

const VARIANT_COLOR = {
  gray: {
    background: 'var(--tw-color-primary-100)',
    backgroundDark: 'var(--tw-color-primary-200)',
    text: 'var(--tw-color-typo-primary)',
  },
  orange: {
    background: 'var(--tw-color-secondary-500)',
    backgroundDark: 'var(--tw-color-secondary-600)',
    text: 'var(--tw-color-typo-alternative)',
  },
};

/**
 * BoxQuantity
 */
export const BoxQuantity: FunctionComponent<TypeBoxQuantityProps> = ({
  variant = 'gray',
  children,
  ...props
}: TypeBoxQuantityProps) => (
  <svg viewBox="0 0 60 50" fill="none" {...props}>
    <path
      fill={VARIANT_COLOR[variant].backgroundDark}
      d="M5.556 0h48.888L60 8H0l5.556-8Z"
    />

    <path fill={VARIANT_COLOR[variant].background} d="M0 8h60v42H0z" />

    <rect
      width="31.921"
      height="5.663"
      x="14.04"
      y="13"
      fill={VARIANT_COLOR[variant].backgroundDark}
      rx="2.832"
    />

    <text
      xmlSpace="preserve"
      fill={VARIANT_COLOR[variant].text}
      fontFamily="var(--font-family-primary)"
      fontSize="24"
      letterSpacing="1"
      style={{ whiteSpace: 'pre', userSelect: 'none' }}
      x="50%"
      y="41.667"
      textAnchor="middle"
    >
      {children}
    </text>
  </svg>
);

BoxQuantity.displayName = 'BoxQuantity';
